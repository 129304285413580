<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Manage {{categoryName}} category</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>\ Category
                  </div>
                </div>
                <div class="breadcrumb-right">
                  <div class="card-toolbar">

                    <v-btn
                        @click="openDialog()"
                        class="mt-1 btn btn-primary"
                        style="color:#fff;"
                    >
                      <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                      Add new
                    </v-btn>

                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="">
                <table class="table">
                  <thead>
                    <tr class="px-3">
                    <th>Title</th>
                    <th>Type</th>
                    <th>Parent</th>
                    <th>Status</th>
                      <th class="pr-3 text-center">Action</th>
                  </tr>
                  </thead>
                  <tbody >
                  <tr v-show="categories.length > 0" v-for="(category, index) of categories" :key="index">
                    <td>{{category.title}}</td>
                    <td>
                                      <span class="badge badge-primary">
                                        {{category.type?category.type:'-'}}
                                      </span>
                    </td>
                    <td>{{category.parent_title?category.parent_title:'-'}}</td>
                    <td>
                                      <span>
                                        <span v-if="category.is_active" class="badge badge-success">Active</span>
                                           <span v-else class="badge badge-danger">Inactive</span>
                                      </span>
                    </td>


                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="openDialog(category)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteCategory(category.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  </tbody>

                  <tr v-if="categories.length == 0">
                    <td colspan="5">Data not available</td>
                  </tr>

                </table>
              </div>

            </div>
            <!-- create and update -->
                <create-and-update ref="category" @refresh="getCatgories"></create-and-update>
            <!-- <pre>{{category}}</pre> -->
        </div>
        </div>
      </div>
    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import BlogCategoryService from "@/core/services/blog/category/BlogCategoryService";
    import CreateAndUpdate from "./CreateAndUpdate";

    const categoryService = new BlogCategoryService();
    export default {
        name:'blog-category',

        components:{
            CreateAndUpdate
        },
        validations: {
            category: {
                title: {required},
                type: {required},
            }
        },
        data() {
            return {
                categories: [],
                dialog: false,
                category: {
                    title: null,
                    type: null,
                    parent_id: null,
                    is_parent: true,
                    is_active: true
                },

                parent_categories: []
            };
        },
        computed: {
            categoryName() {
                return this.$route.params.category;
            }
        },
        mounted() {
            this.getCatgories();
            this.getParentCategories();
        },
        methods: {
            getParentCategories() {
                categoryService
                    .parentCategories(this.category.type)
                    .then(response => {
                        this.parent_categories = response.data.data;
                    })
                    .catch(error => {
                        //console.log(error);
                    });
            },
            getCatgories() {
                categoryService
                    .paginate(this.categoryName)
                    .then(response => {
                        this.categories = response.data.data;
                    })
                    .catch(error => {
                        //console.log(error);
                    });
            },

            createOrUpdate() {
                this.$v.category.$touch();
                if (this.$v.category.$error) {
                    setTimeout(() => {
                        this.$v.category.$reset();
                    }, 3000);
                } else {
                    if (this.edit) {
                        this.udpateCategory();
                    } else {
                        this.createCategory();
                    }
                }
            },
            createCategory() {
                categoryService
                    .store(this.category)
                    .then(response => {
                        this.$snotify.success("Information added");

                        this.resetForm();
                    })
                    .catch(error => {
                        //console.log(error);
                    });
            },
            udpateCategory() {
                categoryService
                    .update(this.category.id, this.category)
                    .then(response => {
                        this.$snotify.success("Information updated");
                        this.resetForm();
                    })
                    .catch(error => {
                        //console.log(error);
                    });
            },
            deleteCategory(id) {

                this.$confirm(
                    {
                        message: `Are you sure?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                categoryService
                                    .delete(id)
                                    .then(response => {
                                        this.$snotify.success("Information deleted");
                                        this.getCatgories();
                                    })
                                    .catch(error => {
                                        //console.log(error);
                                    });


                            }
                        }
                    }
                )
            },
            openDialog(category) {
                this.$refs['category'].openDialog(category, null)
                // this.$refs["my-modal"].show();
            },
            hideModal() {
                this.$refs["my-modal"].hide();
            },
            resetForm() {
                this.edit = false;
                this.category = {
                    title: null,
                    parent_id: null,
                    is_parent: true,
                    is_active: true,
                      type: null,
                };
                this.$v.$reset();
                this.hideModal();
                this.getCatgories();
                this.getParentCategories();
            },
            closeDialog() {
                this.dialog = false;
            }
        }
    };
</script>
